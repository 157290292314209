/**
 * FDIC
 */

 // Asynchronous Action Types
 const FETCH_PERIOD_DATES_REQUEST = 'FETCH_PERIOD_DATES_REQUEST';
 const FETCH_PERIOD_DATES_FAILURE = 'FETCH_PERIOD_DATES_FAILURE';
 const FETCH_PERIOD_DATES_SUCCESS = 'FETCH_PERIOD_DATES_SUCCESS';

 const FETCH_FILER_IDS_REQUEST = 'FETCH_FILER_IDS_REQUEST';
 const FETCH_FILER_IDS_FAILURE = 'FETCH_FILER_IDS_FAILURE';
 const FETCH_FILER_IDS_SUCCESS = 'FETCH_FILER_IDS_SUCCESS';

 const FETCH_CALL_REPORTS_REQUEST = 'FETCH_CALL_REPORTS_REQUEST';
 const FETCH_CALL_REPORTS_FAILURE = 'FETCH_CALL_REPORTS_FAILURE';
 const FETCH_CALL_REPORTS_SUCCESS = 'FETCH_CALL_REPORTS_SUCCESS';

 const FETCH_NEWS_REQUEST = 'FETCH_NEWS_REQUEST';
 const FETCH_NEWS_FAILURE = 'FETCH_NEWS_FAILURE';
 const FETCH_NEWS_SUCCESS = 'FETCH_NEWS_SUCCESS';

// Synchronous Action Types
const SELECT_PERIOD_DATE = 'SELECT_PERIOD_DATE';
const SELECT_FILER_ID = 'SELECT_FILER_ID';

export default {
 
  /**
   * FDIC
   */
  
  // Asynchronous Action Types
  FETCH_PERIOD_DATES_REQUEST,
  FETCH_PERIOD_DATES_FAILURE,
  FETCH_PERIOD_DATES_SUCCESS,
  FETCH_FILER_IDS_REQUEST,
  FETCH_FILER_IDS_FAILURE,
  FETCH_FILER_IDS_SUCCESS,
  FETCH_CALL_REPORTS_REQUEST,
  FETCH_CALL_REPORTS_FAILURE,
  FETCH_CALL_REPORTS_SUCCESS,
  
   // Synchronous Action Types
  SELECT_PERIOD_DATE,
  SELECT_FILER_ID,

  /**
   * NEWS
   */

  // Asynchoronous Action Types
  FETCH_NEWS_REQUEST,
  FETCH_NEWS_FAILURE,
  FETCH_NEWS_SUCCESS,

  
}